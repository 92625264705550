import InboundProduct from '@/components/dialogs/inbound-product'
export default {
  components: {
    InboundProduct
  },
  methods: {
    onInfoClose() {
      this.$router.push("/order-centric");
    }
  }
}